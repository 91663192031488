@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&family=Koulen&family=Montserrat&family=Playfair+Display:ital,wght@1,700&family=Spectral:wght@600&display=swap');
.Backgy{
    /*background-image: linear-gradient(rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3)),url("https://images.unsplash.com/photo-1556005693-00fff02f134c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80");
    */
    background-repeat: no-repeat;
    background-color: #26272b;
  background-size: cover;
  text-align: center;
    top: 20px;
    height: 240px;
    position: relative;
    left: 2%;
    width: 96%;
}
.trontitle{
    position: relative;
    color: whitesmoke;
    right: 3%;
    top: 50%;
    text-align: right;
    font-family:'Spectral', serif;
}
.trontitle h1{
    font-size: 70px;
    font-weight: 800;
    filter: brightness(200%);
}
.trontitle p{
    font-family: serif;
    text-align: right;
    position: relative;
    left: 68%;
    font-size: 21px;
    width: 30%;
}